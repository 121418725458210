<template>
  <a-table  :columns="table.columns" :data-source="table.data" :scroll="{ x: 1000 }" :pagination="false" bordered  class="my-table"></a-table>
</template>
<script>
import { GetStatisticsLog } from "@/api/statistics";
export default {
  data() {
    return {
        id: '',
        table: {
        columns: [
          {
            key: 'name',
            dataIndex: 'name',
            title: '员工名称',
            width: 100,
          },
          {
            key: 'department_name',
            dataIndex: 'department_name',
            title: '部门名称',
            width: 100,
          },
          {
            key: 'add_contact',
            dataIndex: 'add_contact',
            title: '当日新增客户数',
            align: 'center',
            width: 120,
          },
          {
            key: 'loss_contact',
            dataIndex: 'loss_contact',
            title: '当日流失客户数',
            align: 'center',
            width: 120,
          },
          {
            key: 'count_contact',
            dataIndex: 'count_contact',
            title: '累计客户数',
            align: 'center',
            width: 120,
          },
          {
            key: 'contact_tag_ratio',
            dataIndex: 'contact_tag_ratio',
            title: '累计标签率',
            align: 'center',
            width: 120,
          }, {
            key: 'contact_mobile_ratio',
            dataIndex: 'contact_mobile_ratio',
            title: '电话备注率',
            align: 'center',
            width: 120,
          }
        ],
        data: []
      },
    };
  },
  created() {
      this.id = this.$route.query.id;
      this.getDetail();
    },
    methods: {
      async getDetail() {
          const response = await GetStatisticsLog({ id: this.id });
          if (response.code === 200) {
            this.table.data= response.data
          }
      }
    },
};
</script>
<style scoped>
  .my-table {
  table-layout: fixed;
}
</style>
