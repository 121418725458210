<template>
  <div class="index">
    <div class="search">
      <van-search
        v-model="searchVal"
        shape="round"
        show-action
        placeholder="请输入工单名称或客户名称"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">
            <img
              class="searchIcon"
              src="../../assets/workOrder/search.png"
              alt=""
            />
          </div>
        </template>
      </van-search>
    </div>
    <div class="skip" v-show="is_my != 1">
      <div class="st" @click="$router.push('/workOrder/statistics')">
        <img class="icon1" src="../../assets/workOrder/icon1.png" alt="" />
        <div class="name">工单统计</div>
      </div>
      <div class="st" @click="$router.push('/workOrder/workOrderPool')">
        <img class="icon1" src="../../assets/workOrder/icon2.png" alt="" />
        <div class="name">工单池</div>
      </div>
      <div
        class="st"
        @click="
          $router.push({ path: '/workOrder/myOrder', query: { is_my: 1 } })
        "
      >
        <img class="icon1" src="../../assets/workOrder/icon3.png" alt="" />
        <div class="name">我的工单</div>
      </div>
    </div>
    <div class="select">
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item v-model="selectVal1" :options="option1" />
        <van-dropdown-item v-model="selectVal2" :options="option2" />
      </van-dropdown-menu>
    </div>
    <van-loading v-if="isGet == false" style="text-align: center" size="24px"
      >加载中...</van-loading
    >
    <div class="list" v-else>
      <div style="height: 100%; overflow: auto" v-if="listData.length > 0">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="main" v-for="(item, index) in listData" :key="index">
            <div class="content" @click="skipDetail(item.id)">
              <div class="top">
                <div class="topLeft">{{ item.name }}</div>
                <div class="topRight" v-if="item.status == 2">待审批</div>
                <div
                  class="topRight"
                  style="color: #9b9b9b"
                  v-if="item.status == 3"
                >
                  已完成
                </div>
                <div
                  class="topRight"
                  style="color: #9b9b9b"
                  v-if="item.status == 4"
                >
                  审批驳回
                </div>
              </div>
              <div class="address">
                <div class="common">
                  经营地址
                  {{ item.addr }}
                </div>
                <div class="icon" v-if="item.status == 2">
                  <img
                    class="examine"
                    src="../../assets/workOrder/examine.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="address">
                <div class="common">客户名称 {{ item.contactName }}</div>
              </div>
              <div class="address">
                <div class="common" style="width: 60%">
                  发起时间 {{ item.createdAt }}
                </div>
                <div class="name">发起人 {{ item.create_employee_name }}</div>
              </div>
            </div>
          </div>
        </van-list>
        <div style="height: 10px"></div>
      </div>
      <van-empty
        v-else
        class="custom-image"
        :image="require('../../assets/workOrder/empty.png')"
        description="暂无数据"
      />
    </div>

    <div
      class="fix"
      @click="$router.push('/workOrder/create')"
      v-show="is_my != 1"
    >
      <img class="plusBtn" src="../../assets/workOrder/plus.png" alt="" />
    </div>
  </div>
</template>
<script>
import { orderList } from "@/api/workOrder";
import { getCurrentTime, getMonday, getfirstDayOfMonth } from "@/utils/index";
export default {
  data() {
    return {
      is_my: 0,
      showOverlay: true,
      searchVal: "",
      selectVal1: 2,
      selectVal2: "",
      listData: [],
      loading: false,
      finished: false,
      page: 1,
      isGet: false,
      length: 0,
      option1: [
        { text: "全部", value: "" },
        { text: "待审批", value: 2 },
        { text: "已完成", value: 3 },
        { text: "已驳回", value: 4 },
      ],
      option2: [{ text: "全部", value: "" }],
    };
  },
  created() {
    this.is_my = this.$route.query.is_my ? this.$route.query.is_my : 0;
    if (this.is_my != 0) {
      this.selectVal1 = "";
      this.listData = []
    } else {
      this.getList();
    }
    var time = getCurrentTime();
    this.option2.push({ text: "今日", value: time });
    var str = JSON.stringify(getMonday());
    var monday = str.substring(1, 11) + " " + "00" + ":" + "00" + ":" + "01";
    this.option2.push({ text: "本周", value: monday });
    var firstDay = getfirstDayOfMonth() + " " + "00" + ":" + "00" + ":" + "02";
    this.option2.push({ text: "本月", value: firstDay });
    console.log("dfdf", this.option2);
  },
  watch: {
    selectVal1() {
      this.reset();
    },
    selectVal2() {
      this.reset();
    },
  },
  methods: {
    getList() {
      orderList({
        // is_my: this.is_my,
        status: this.selectVal1,
        startTime: this.selectVal2,
        search: this.searchVal,
        page: this.page,
        perPage: 20,
      }).then((res) => {
        this.isGet = true;
        this.listData.push(...res.data.list);
        this.length = res.data.page.total;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.listData.length >= res.data.page.total) {
          this.finished = true;
        } else {
          this.finished = false;
        }
      });
    },
    onLoad() {
      this.page++;
      // this.getList();
    },
    reset() {
      this.isGet = false;
      this.finished = false;
      this.listData = [];
      this.page = 1;
      this.getList();
    },
    onSearch() {
      this.reset();
    },
    skipDetail(id) {
      this.$router.push({ path: "/workOrder/detail", query: { id } });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-search {
  background-color: #3975c6;
}
.index {
  min-height: 100%;
  background-color: #f2f3f7;
}
.search {
  width: 100%;
  height: 64px;
  background: #3975c6;
  padding-top: 5px;
  .searchIcon {
    width: 25px;
    height: 25px;
  }
}
.skip {
  margin-top: -2px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background: #3975c6;

  .st {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon1 {
      width: 26px;
      height: 26px;
    }
    .name {
      padding-top: 4px;
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.list {
  background-color: #f2f3f7;
  height: calc(100% - 74px);
  .main {
    width: 93%;
    min-height: 157px;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 14px;
    .content {
      width: 94%;
      margin: 0 auto;
      overflow: hidden;

      .top {
        display: flex;
        height: 56px;
        line-height: 56px;
        justify-content: space-between;
        border-bottom: 1px solid #f1f1f1;
        margin-bottom: 5px;
        .topLeft {
          width: 248px;
          height: 56px;
          line-height: 56px;
          font-size: 16px;
          font-weight: bold;
          color: #000000;
          letter-spacing: 1px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .topRight {
          height: 56px;
          line-height: 56px;
          font-size: 12px;
          font-weight: 500;
          color: #3975c6;
        }
      }
      .address {
        display: flex;
        align-items: center;
        min-height: 28px;
      }
      .common {
        font-size: 12px;
        color: #9b9b9b;
        display: inline-block;
        width: 90%;
      }
      .icon {
        display: inline-block;
        width: 10%;
        text-align: right;
      }
      .examine {
        width: 17px;
        height: 20px;
      }
      .name {
        width: 40%;
        text-align: right;
        font-size: 12px;
        color: #9b9b9b;
        display: inline-block;
      }
    }
  }
}
.fix {
  position: fixed;
  right: 2%;
  bottom: 18%;
  .plusBtn {
    width: 38px;
    height: 38px;
  }
}
</style>
