<template>
  <div class="index">
   <workOrder />
  </div>
</template>
<script>
import workOrder from './index.vue'
export default {
  components: {workOrder},
  data() {
    return {
    } 
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
.index{
  height: 100%;
}
</style>
