<template>
    <div class="detailPage">
        <div class="lineBox">
            <div class="boxLeft">打卡时间</div>
            <div class="boxRight">{{detailData.clockTime}}</div>
        </div>
        <div class="lineBox">
            <div class="boxLeft">打卡地址</div>
            <div class="boxRight">{{detailData.addr|showDefult}}</div>
        </div>
        <div class="lineBox">
            <div class="boxLeft">客户名称</div>
            <div class="boxRight">{{detailData.contactName}}</div>
        </div>
        <div class="lineBox">
            <div class="boxLeft">联系方式</div>
            <div class="boxRight">{{detailData.contactPhone|showDefult}}</div>
        </div>
        <div class="lineBox">
            <div class="boxLeft">备注</div>
            <div class="boxRight">{{detailData.remark|showDefult}}</div>
        </div>
        <div class="lineBox">
            <div class="boxLeft">拍照记录</div>
            <div class="boxRight"><img :src="detailData.clockImg" alt=""></div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                detailData: {}
            }
        },
        filters: {
            showDefult(value) {
                return value == '' ? '-' : value
            }
        },
        created() {
            this.detailData = JSON.parse(this.$route.query.detail)
        },
        mounted() {
        },
        methods: {

        }
    }
</script>
<style lang="less" scoped>
    .detailPage {
        padding: 14px;

        .lineBox {
            width: 100%;
            display: inline-flex;
            justify-content: space-between;

            .boxLeft {
                width: 25%;
                font-size: 15px;
                font-weight: 400;
                color: #9B9B9B;
            }

            .boxRight {
                text-align: right;
                width: 70%;
                font-size: 15px;
                font-weight: 400;
                color: #515151;

                img {
                    width: 110px;
                    height: 80px;
                }
            }
        }
    }
</style>